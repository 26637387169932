<script setup lang="ts">
import type { SearchProductsItem } from '@yescapa-dev/ysc-api-js'

export interface AppSearchResultsCamperCardProps {
  camper: SearchProductsItem
  /** If defined and selectedDateTo is defined, the link to the camper page will have those date preselected */
  selectedDateFrom?: string | null
  /** If defined and selectedDateFrom is defined, the link to the camper page will have those date preselected */
  selectedDateTo?: string | null
  /** Whether the camper is in one of the user wishlist */
  isInWishlist?: boolean
  /** Whether to show the button allowing the user to add/remove the camper from a wishlist */
  showWishlistButton?: boolean
  /** Whether to display the discount tag if one is available */
  showDiscount?: boolean
}

defineEmits<{
  'wishlist-click': [number]
}>()

const props = withDefaults(defineProps<AppSearchResultsCamperCardProps>(), {
  isInWishlist: false,
  selectedDateFrom: null,
  selectedDateTo: null,
  showWishlistButton: true,
  showDiscount: true,
})

const { bookingPricePerDay, bookingTotalPrice, bookingOriginalPrice, startingPrice } = useSearchCamperDisplayPrice(() => props.camper)

const passengerCount = computed(() => getCamperDisplayTravelersCount(props.camper))
</script>

<template>
  <YscCamperCardV2
    :key="camper.id"
    :camper-id="camper.id"
    :title="useVehicleType({ type: camper.vehicle_type }) ?? ''"
    :location-label="camper.vehicle_location_city"
    :booking-daily-price="bookingPricePerDay"
    :passengers-count="passengerCount"
    :booking-total-price="bookingTotalPrice"
    :booking-total-price-before-discount="bookingOriginalPrice"
    :starting-price="startingPrice"
    :camper-url="buildCampersIdRoute({ camper, dateFrom: selectedDateFrom, dateTo: selectedDateTo })"
    :is-in-wishlist="isInWishlist"
    :has-instant-booking="camper.instant_booking_activated"
    :rating="camper.display_review_average"
    :is-best-owner="camper.is_ambassador"
    :is-new="camper.is_new"
    :has-discount="showDiscount && camper.discounts_types.length > 0"
    :show-wishlist-button="showWishlistButton"
    show-link
    @wishlist-click="$emit('wishlist-click', parseInt($event.toString()))"
  >
    <template #slideshow>
      <AppCamperCardSlideShow
        :items="camper.picture_set.slice(0, 10)"
        :camper-id="camper.id"
        :vehicle-type="camper.vehicle_type"
      />
    </template>
  </YscCamperCardV2>
</template>

import type { SearchProductsItem } from '@yescapa-dev/ysc-api-js'

export const useSearchCamperDisplayPrice = (camper: MaybeRefOrGetter<SearchProductsItem>) => {
  const bookingPricePerDay = computed(() => {
    const camperValue = toValue(camper)
    return camperValue.booking_price_per_day
      ? {
          value: parseFloat(camperValue.booking_price_per_day.toString()),
          currency: camperValue.booking_price_per_day_currency,
        }
      : undefined
  })

  const bookingTotalPrice = computed(() => {
    const camperValue = toValue(camper)

    return camperValue.final_booking_price
      ? {
          value: camperValue.final_booking_price,
          currency: camperValue.final_booking_price_currency,
        }
      : undefined
  })
  const bookingOriginalPrice = computed(() => {
    const camperValue = toValue(camper)
    return camperValue.original_booking_price
      ? {
          value: camperValue.original_booking_price,
          currency: camperValue.original_booking_price_currency,
        }
      : undefined
  })
  const startingPrice = computed(() => {
    const camperValue = toValue(camper)
    return { value: camperValue.price_per_day, currency: camperValue.price_per_day_currency }
  })

  return {
    startingPrice,
    bookingOriginalPrice,
    bookingTotalPrice,
    bookingPricePerDay,
  }
}
